// src/ReportsTable.js
import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://xvengagkexybgjsiqtnd.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inh2ZW5nYWdrZXh5Ymdqc2lxdG5kIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTcxMTg3OTQ0NywiZXhwIjoyMDI3NDU1NDQ3fQ.AmwtOnJVmjNx4KM6t12YTHbulBTTxqroG_Ts423S5JA';
const supabase = createClient(supabaseUrl, supabaseKey);

/**
 * ReportsTable component displays the reports and listens for changes.
 */
const ReportsTable = () => {
    const [reports, setReports] = useState([]);
    const [logs, setLogs] = useState([]);
  
    useEffect(() => {
      // Fetch initial reports
      const fetchReports = async () => {
        const { data, error } = await supabase
          .from('events')
          .select('*')
          .eq('processed', 'TRUE');
        if (error) console.error('Error fetching reports:', error);
        else setReports(data);
      };
  
      fetchReports();
  
      // Subscribe to changes in the 'reports' table
      const subscription = supabase
        .channel('public:events')
        .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'reports' }, payload => {
          if (payload.new.status === 'pending') {
            setReports(prev => [...prev, payload.new]);
            setLogs(prev => [
              ...prev,
              `New report inserted: ID ${payload.new.id}, URL ${payload.new.url}`
            ]);
          }
        })
        .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'reports' }, payload => {
          if (payload.new.processed === 'TRUE') {
            setReports(prev =>
              prev.map(report =>
                report.id === payload.new.id ? payload.new : report
              )
            );
            setLogs(prev => [
              ...prev,
              `Report completed: ID ${payload.new.id}, Results: ${JSON.stringify(payload.new.results)}`
            ]);
          }
        })
        .subscribe();
  
      // Cleanup subscription on component unmount
      return () => {
        subscription.unsubscribe();
      };
    }, []);
  
    return (
      <div>
        <h1>Console Log</h1>
        <div className="console-log">
          {logs.map((log, index) => (
            <div key={index}>{log}</div>
          ))}
        </div>
        <h1>Pending Reports</h1>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>group</th>
              <th>category</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {reports.map(report => (
              <tr key={report.id}>
                <td>{report.id}</td>
                <td>{report.group}</td>
                <td>{report.category}</td>
                <td>{report.score}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  
  export default ReportsTable;