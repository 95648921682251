// src/App.js
import React from 'react';
import ReportsTable from './ReportsTable';
import './App.css';

/**
 * App component renders the ReportsTable.
 */
const App = () => {
  return (
    <div className="App">
      <ReportsTable />
    </div>
  );
};

export default App;
